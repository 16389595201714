import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";

const background1 =
  "linear-gradient(to right, rgb(71, 118, 230, 61%), rgb(142, 84, 233, 49%))";
const background2 =
  "linear-gradient(to right, rgb(0, 23, 200, 39%), rgb(58, 70, 213, 55%))";
// "linear-gradient(to right, rgb(0, 210, 255, 31%), rgb(58, 123, 213, 62%))";

const faqs = [
  {
    id: 1,
    Q: "What sets Ratofy apart from other social platforms?",
    A: "Ratofy prioritizes community building with a central feed for community updates, expressive text-to-image features, and embraces Web3 technology for token-gated exclusivity.",
  },
  {
    id: 2,
    Q: "How do I get started on Ratofy?",
    A: "Getting started is easy! Download the Ratofy app from your app store, sign up using your email or social media account, and begin exploring and connecting with communities.",
  },
  {
    id: 3,
    Q: "Is Ratofy completely free to use?",
    A: "Yes, Ratofy is free for everyone to join and enjoy. However, keep an eye out for premium features and content that may require a subscription or purchase.",
  },
  {
    id: 4,
    Q: "What does 'Web3 integration' mean on Ratofy?",
    A: "Web3 integration means Ratofy is embracing blockchain and decentralized technology, bringing token-gated content, secure transactions, and access to decentralized apps (DApps).",
  },
  {
    id: 5,
    Q: "How can I find or create communities on Ratofy?",
    A: "Discovering communities is easy! Use the 'Explore Feed' to search for interests or browse suggested communities. You can also create your own and invite like-minded members.",
  },
  {
    id: 6,
    Q: "What should I do if I come across inappropriate content or users?",
    A: "Ratofy offers reporting tools. If you encounter content or users that violate guidelines, report them, and our team will take necessary action.",
  },
  {
    id: 7,
    Q: "Can you explain 'token-gated communities' in more detail?",
    A: "Token-gated communities are exclusive groups that require members to hold specific tokens for access, adding exclusivity and incentives for community members.",
  },
  {
    id: 8,
    Q: "How do I obtain tokens on Ratofy?",
    A: "You can earn tokens by actively participating in community activities, purchasing them on supported exchanges, or through engagement and contributions.",
  },
  {
    id: 9,
    Q: "Is my cryptocurrency secure on Ratofy?",
    A: "Yes, Ratofy takes crypto security seriously and implements industry-standard measures to protect your assets. Use strong passwords and enable two-factor authentication for extra safety.",
  },
];

function FAQ() {
  const [showFAQ, setShowFAQ] = useState("");

  const handleSelect = (item) => {
    if (item.id == showFAQ) {
      setShowFAQ("");
    } else setShowFAQ(item.id);
  };

  return (
    <div className="w-full flex items-center flex-col lg:my-[100px] my-[50px]">
      <div
        className="xl:w-[80%] w-[90%] xl:py-[10px] py-1 flex justify-center items-center"
        style={{ background: background1 }}
      >
        <h1 className="my-font md:text-5xl text-4xl">FAQ</h1>
      </div>
      <div
        className="xl:w-[80%] w-[90%] py-6 "
        style={{ background: background2 }}
      >
        {faqs.map((item) => (
          <div className="flex flex-col gap-1 mb-5 w-[90%] mx-auto text-white card-font">
            <h1
              onClick={() => handleSelect(item)}
              className="flex items-start justify-between space-x-2 font-[600] lg:text-xl sm:text-lg text-md hover:cursor-pointer"
            >
              <span>{item.Q}</span>
              <span className="pt-2">
                {showFAQ == item.id ? (
                  <IoMdArrowDropup />
                ) : (
                  <IoMdArrowDropdown />
                )}
              </span>
            </h1>
            {showFAQ == item.id && (
              <h1 className="font-[500] lg:text-lg sm:text-md text-sm">
                {item.A}
              </h1>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
