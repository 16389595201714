import { BrowserRouter, Link } from "react-router-dom";
import img1 from "./assets/img1.png";
import img2 from "./assets/img2.png";
import img3 from "./assets/img3.png";
import img4 from "./assets/img4.png";
// import backgroundImage from "./assets/backgroundImage.png";
import play from "./assets/play.png";
import pass from "./assets/pass.png";
import Features1 from "./Components/Features1";
import Features2 from "./Components/Features2";
import Partners from "./Components/Partners";
import FAQ from "./Components/FAQ";
import "./App.css";

function GradientWall({ img }) {
  return (
    <>
      <div className="relative h-1/4">
        <div
          className="absolute w-full h-full"
          style={{
            background: "rgba(0,0,0,.8)",
          }}
        ></div>
        <div
          className="bg-div w-full h-full"
          style={{ backgroundImage: `url(${img})` }}
        ></div>
      </div>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <div className="relative w-full flex justify-center bg-black">
        <div className="absolute h-full w-full">
          <div className="relative h-1/4">
            <div
              className="absolute w-full h-full"
              style={{
                background: "rgb(0,0,0)",
                background:
                  "linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 59%, rgba(255,255,255,0) 80%)",
              }}
            ></div>
            <div
              className="bg-div w-full h-full"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
          </div>
          <GradientWall img={img2} />
          <GradientWall img={img3} />
          <GradientWall img={img4} />
        </div>

        <div className="z-10 flex flex-col w-full items-center">
          <h1 className="my-font lg:text-8xl md:text-7xl sm:text-6xl text-5xl  md:font-medium font-base lg:mt-[200px] md:mt-[250px] sm:mt-[250px] mt-[220px] ">
            Ratofy is Here !
          </h1>
          <p className="my-desc md:mt-7 mt-4 lg:text-3xl md:text-2xl sm:text-xl text-md font-bold text-white">
            ~Social Media For Communities~
          </p>
          <div className="flex flex-wrap md:gap-16 sm:gap-10 gap-4 md:mt-20 mt-10 h-20">
            <Link to="https://play.google.com/store/apps/details?id=com.ratofy.app.ratofy">
              <button className="google-btn flex items-center space-x-2 lg:px-[12px] md:px-[8px] sm:px-[7px] px-[6px] pb-1 my-font bg-black border-2 rounded-xl hover:-mt-1 duration-100 ease-in">
                <img
                  className="lg:w-[50px] md:w-[40px] sm:w-[35px] w-[26px]"
                  src={play}
                />
                <div className="flex flex-col text-start">
                  <p className="mt-1 lg:text-[18px] md:text-[14px] sm:text-[12px] text-[10px] font-normal">
                    GET IT ON
                  </p>
                  <h1 className="-mt-2 lg:text-[30px] md:text-[25px] sm:text-[20px] text-[15px] font-base ">
                    Google Play
                  </h1>
                </div>
              </button>
            </Link>
            <Link to="https://tally.so/r/3jozE1">
              <button
                className="flex md:space-x-3 sm:space-x-2 space-x-1 justify-center items-center  my-font lg:text-[33px] md:text-[28px] sm:text-[22px] text-[18px] lg:px-[40px] md:px-[30px] sm:px-[20px] px-[15px] lg:py-[12px] md:py-[14px] py-[8px] rounded-xl hover:-mt-1 duration-100 ease-in"
                style={{
                  background:
                    "linear-gradient(to right, rgba(242, 112, 156, 1), rgba(255, 148, 114, 1))",
                }}
              >
                <h1>Access Pass</h1>
                <img
                  className="lg:w-[28px] md:w-[24px] sm:w-[20px] w-[18px] "
                  src={pass}
                  alt="pass"
                />
              </button>
            </Link>
          </div>
          {/*-------- Features Cards ----------*/}
          <Features1 />
          {/*-------- Description cards ----------*/}
          <Features2 />
          {/*--------- partners ----------*/}
          <Partners />
          {/*-------- FAQ ---------*/}
          <FAQ />
        </div>
      </div>
    </BrowserRouter>
  );
}
export default App;

{
  /* <div><img className="w-full" src={img1} alt="" /></div>
  <div><img className="w-full" src={img2} alt="" /></div>
  <div><img className="w-full" src={img3} alt="" /></div>
  <div><img className="w-full" src={img4} alt="" /></div> */
}

{
  /* <div className="bg-div" style={{ backgroundImage: `url(${img1})` }}></div>
      <div className="bg-div" style={{ backgroundImage: `url(${img2})` }}></div>
      <div className="bg-div" style={{ backgroundImage: `url(${img3})` }}></div>
      <div className="bg-div" style={{ backgroundImage: `url(${img4})` }}></div> */
}
